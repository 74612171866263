<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Insurer : {{ Insurer }} : {{ Product }}</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <b-tabs v-model="tabIndex" card>

            <b-tab title="Insurer" class="active mt-2">

              <table class="table" v-if="details">
                <tbody>
                    <tr v-for="(v, k, index) in details" :key="index"  v-if="k !== 'id' && k !== 'product_details' && k !== 'settings'">
                      <th>{{ k|capitalize }}</th>
                      <td colspan="2" v-if="k === 'discount_enabled' || k === 'active' || k === 'direct_debit_enabled'">
                        <i v-if="v === true || v === 'true'" class="fas fa-check text-success"></i>
                        <i v-else class="fas fa-times text-danger"></i>
                      </td>
                      <td colspan="2" v-else v-html="v">{{ v }}</td>
                    </tr>
                </tbody>

              </table>

            </b-tab>

            <b-tab title="Settings" class="mt-2" href="#setting">

              <table class="table">
                <tbody>
                    <tr>
                      <th>Setting</th>
                      <th>Value</th>
                      <th>Version</th>
                      <th>
                        <b-button size="sm" variant="outline-success" :to="{ name: 'InsurerSettingAdd', params: { product: $route.params.product, insurer: $route.params.insurer} }">Add</b-button>
                      </th>
                    </tr>
                    <tr v-for="(setting, index) in details.settings" :key="index">
                      <td>{{ setting.setting_name }}</td>
                      <td>{{ setting.value }}</td>
                      <td>{{ setting.version }}</td>
                      <td>
                        <b-button size="sm" :to="{ name: 'InsurerSettingEdit', params: { product: $route.params.product, insurer: $route.params.insurer, id: setting.id } }" variant="outline-dark">Edit</b-button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td>
                        <b-button size="sm" variant="outline-success" :to="{ name: 'InsurerSettingAdd', params: { product: $route.params.product, insurer: $route.params.insurer} }">Add</b-button>
                      </td>
                    </tr>
                </tbody>
              </table>

            </b-tab>

          </b-tabs>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button v-show="hasFullAccess" :to="{ name: 'InsurerEdit', params: { product: this.$route.params.product, insurer: this.$route.params.insurer } }" variant="outline-dark" class="float-right">Edit</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";

  export default {
    name: 'Insurer',
    components: {Id},
    props: {
      caption: {
        type: String,
        default: 'Insurer Details'
      },
    },
    data: () => {
      return {
        tabIndex: 0,
        tabs: ['#details', '#setting'],
        details: {
         code: "",
         short_name: "",
         full_name: "",
         product: "",
         regulatory_text: "",
         discount_enabled: "",
         active: "",
         product_details: {
           name: ''
         }
        }
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getData() {

        let url = '/insurers/' + this.$route.params.insurer +  '/?product=' + this.$route.params.product;

        axios.get(url).then(
          response => {
            this.details = response.data
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('email setting error');
            console.log(error.response)
          })
        return this.details
      },
    },
    computed: {
      Product() {
        return this.details.product_details.name
      },
      Insurer() {
        return this.details.short_name
      },
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
    },
    mounted() {
      this.getData()
      this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.hash)
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
